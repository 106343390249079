import React, { ReactElement } from "react";
import styles from "./defaultOffers.module.scss";
import colors from "@styles/variables/vpnpricecomparisonVariables.module.scss";
import Image from "next/image";
import { useDomainContext } from "@hooks/useDomainContext";
import { HiddenAttributesKeys } from "@lib/shared/types";
import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { affiliateOfferZones } from "@lib/shared/config";

export default function DefaultOffers(): ReactElement {
    const { domain } = useDomainContext();

    const getSellingPoint = (hiddenAttributes: {
        [key in HiddenAttributesKeys]: string;
    }): string => {
        return hiddenAttributes.sellingPoint;
    };

    const getMonth = () => {
        const date = new Date();
        const month = date.toLocaleString("en-US", { month: "long" });
        return month;
    };

    const bestVpnMonth = `Explore ${getMonth()}'s Top VPN Services, Expertly Tested and Categorized for Your Needs.`;

    const bestVpnYear = `Discover the Top VPN Providers of ${new Date().getFullYear()}`;

    return (
        <>
            {domain?.defaultOffers && (
                <section className="max-w-xl md:max-w-3xl lg:max-w-7xl mx-auto overflow-x-visible mb-12">
                    <h2 className="text-center text-xl font-bold">
                        {bestVpnYear}
                    </h2>
                    <p
                        style={{ color: colors.slightlyBlack }}
                        className="text-center font-light text-sm mt-2"
                    >
                        {bestVpnMonth}
                    </p>
                    <div>
                        <div
                            style={{
                                //@ts-ignore
                                "--number-of-items":
                                    domain?.defaultOffers.length,
                            }}
                            className={`hide-scroll-bar horizontal-scroll ${styles["list"]}`}
                        >
                            {domain.defaultOffers.map((offer, index) => (
                                <div
                                    key={index}
                                    className={`horizontal-scroll-child ${styles["list-item"]}`}
                                >
                                    <div className={styles["inner"]}>
                                        <AffiliateOfferUrl
                                            zone={affiliateOfferZones.HOME_PAGE}
                                            offer={offer}
                                            target="_blank"
                                        >
                                            <span className="block">Best</span>
                                            {getSellingPoint(
                                                offer.hiddenAttributes,
                                            ) && (
                                                <span
                                                    className={
                                                        styles["selling-point"]
                                                    }
                                                >
                                                    {getSellingPoint(
                                                        offer.hiddenAttributes,
                                                    )}
                                                </span>
                                            )}
                                            <span className={styles["logo"]}>
                                                {offer.logo?.fullPath && (
                                                    <Image
                                                        src={
                                                            offer.logo?.fullPath
                                                        }
                                                        layout="intrinsic"
                                                        width={150}
                                                        height={50}
                                                        alt={offer.slug}
                                                    />
                                                )}
                                            </span>
                                        </AffiliateOfferUrl>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}
